<template>
  <!-- Leaderboard Section -->
  <section id="leaderboards" class="h-[2000px] md:h-[2200px] lg:h-[2200px] overflow-hidden leaderboard-bg relative bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E]">

    <!-- <div class="absolute bg-black bg-opacity-30 top-0 left-0 w-full h-full max-h-[1024px] z-[2]"></div> -->
    <img src="../assets/leaderboard-bg.png"
      class="absolute top-0 md:bottom-[1320px] left-0 w-full h-full object-cover object-center max-h-[1024px] z-[1]" />
    <div class="flex items-center justify-center w-full px-4 md:px-0 lg:pt-0">
      <div class="w-full flex flex-col justify-center items-center my-8 md:my-10 md:mt-[120px] md:p-4 rounded-3xl z-10">
        <div class="text-[#F25960] text-3xl md:text-7xl lg:text-4xl text-center leading-[48px] font-black"
          style="font-family: Montserrat, sans-serif">
          <span class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text">LEADERBOARDS</span>
        </div>
        <div class="flex flex-col items-center justify-center my-10 lg:mt-20 lg:mb-5">
          <div class="bg-transparent">
            <div v-motion-slide-visible-bottom class="bg-transparent" style="font-family: Oxanium">
              <div v-if="timer && timer != 'Leaderboard ended'"
                class="flex flex-row md:gap-x-2">
                <div class="relative shadow-[0_0_50px_#9A36F8] rounded-full h-[84px] w-[84px] md:h-[100px] md:w-[100px]">
                  <img src="@/assets/countdown.png" alt="Timer Background"
                    class="absolute inset-0 object-cover h-[84px] w-[84px] md:w-[100px] md:h-[100px]" />
                  <div class="absolute inset-0 flex flex-col items-center justify-center">
                    <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                      {{ timer.split(' ')[0].substring(0, timer.split(' ')[0].length - 1) }}
                    </h4>
                    <h6 class="text-sm leading-6 font-bold text-center text-[#01CCFF]">
                      DAYS
                    </h6>
                  </div>
                </div>

                <div class="relative shadow-[0_0_50px_#9A36F8] rounded-full h-[84px] w-[84px] md:h-[100px] md:w-[100px]">
                  <img src="@/assets/countdown.png" alt="Timer Background"
                    class="absolute inset-0 object-cover h-[84px] w-[84px] md:h-[100px] md:w-[100px]" />
                  <div class="absolute inset-0 flex flex-col items-center justify-center">
                    <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                      {{ timer.split(' ')[1].substring(0, timer.split(' ')[1].length - 1) }}
                    </h4>
                    <h6 class="text-sm leading-6 font-bold text-center text-[#01CCFF]">
                      HRS
                    </h6>
                  </div>
                </div>

                <div class="relative shadow-[0_0_50px_#9A36F8] rounded-full h-[84px] w-[84px] md:h-[100px] md:w-[100px]">
                  <img src="@/assets/countdown.png" alt="Timer Background"
                    class="absolute inset-0 object-cover h-[84px] w-[84px] md:h-[100px] md:w-[100px]" />
                  <div class="absolute inset-0 flex flex-col items-center justify-center">
                    <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                      {{ timer.split(' ')[2].substring(0, timer.split(' ')[2].length - 1) }}
                    </h4>
                    <h6 class="text-sm leading-6 font-bold text-center text-[#01CCFF]">
                      MIN
                    </h6>
                  </div>
                </div>

                <div class="relative shadow-[0_0_50px_#9A36F8] rounded-full h-[84px] w-[84px] md:h-[100px] md:w-[100px]">
                  <img src="@/assets/countdown.png" alt="Timer Background"
                    class="absolute inset-0 object-cover h-[84px] w-[84px] md:h-[100px] md:w-[100px]" />
                  <div class="absolute inset-0 flex flex-col items-center justify-center">
                    <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                      {{ timer.split(' ')[3].substring(0, timer.split(' ')[3].length - 1) }}
                    </h4>
                    <h6 class="text-sm leading-6 font-bold text-center text-[#01CCFF]">
                      SEC
                    </h6>
                  </div>
                </div>
              </div>
              <div v-else class="text-white text-2xl md:text-[36px] md:leading-[48px] font-bold py-[11px]">
                Leaderboard Ended
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row justify-center items-center mb-20 md:mb-[105px]"
        >
          <div class="block">
            <label
              class="toggle-switch md:mt-8 bg-tansparent"
              style="font-family: Lato, sans-serif"
            >
              <input
                type="checkbox"
                @change="changeLeaderboard()"
                v-model="selectedLeaderboard"
              />
              <div class="slider"></div>
              <div
                class="left-label relative py-4 md:py-6 w-[300px] h-[72px] rounded-[10px]"
                :class="{
          'text-white': selectedLeaderboard,
          'text-[#0E0E0E]': !selectedLeaderboard
        }"
              >
                <img
                  :src="activeTabImage"
                  :class="selectedLeaderboard === false ? 'hidden' : ''"
                  class="absolute top-0 right-0 w-[300px] h-[72px] z-10"
                />
                <p
                  class="absolute inset-0 top-5 text-center text-lg md:leading-[28px] font-extrabold z-20"
                >
                  CURRENT LEADERBOARD
                </p>
              </div>
              <div
                class="right-label relative py-4 md:py-6 w-[300px] h-[52px] md:h-[72px] rounded-[10px]"
                :class="{
          'text-white': !selectedLeaderboard,
          'text-[#0E0E0E]': selectedLeaderboard
        }"
              >
                <img
                  :src="inActiveTabImage"
                  :class="selectedLeaderboard && 'hidden'"
                  class="absolute top-0 right-0 w-[300px] h-[72px] z-10"
                />
                <p
                  class="absolute inset-0 top-5 text-center text-lg md:leading-[28px] font-extrabold z-20"
                >
                  PREVIOUS LEADERBOARD
                </p>
              </div>
            </label>
          </div>
        </div>
        <div v-if="loading == true" class="mt-10 text-xl font-bold text-center text-white md:text-4xl"
          style="font-family: Secular One, sans-serif">
          Loading Leaderboard...
        </div>
        <div v-if="loading == false" class="relative w-full mt-24 md:mt-0">
          <div
            class="flex flex-row justify-center items-center mt-44 gap-[34px] md:gap-10 xl:gap-12 md:mb-16 lg:mb-0 lg:mt-16">
            <div v-motion-pop-visible
              class="flex flex-col items-center mt-28 h-full md:h-[260px] lg:h-full  md:mt-44 lg:mt-[88px] border-t border-b border-solid"
              style="box-shadow: -4px -4px 30px 3px rgba(154, 54, 248, 1), 4px 4px 30px 3px rgba(154, 54, 248, 1); border-image: linear-gradient(to left, #01CCFF, #9A36F8) 1;">
              >
              <div
                class="p-2.5 pb-[13px] md:pb-1 bg-gradient-to-r rounded-none w-[150px] md:w-[200px] lg:w-[280px] xl:w-[325px] bg-opacity-5">
                <div class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl">
                  <div
                    class="bg-gradient-to-tl w-[70px] lg:w-[146px] from-[#01CCFF] to-[#9A36F8] -mt-[78px] lg:-mt-[120px] -ml-10 h-[70px] lg:h-[146px] rounded-full overflow-hidden top-0 translate-x-4">
                    <div
                      class="absolute inset-0 m-[2px] bg-[#484C97] flex items-center justify-center overflow-hidden rounded-full">
                      <div
                        class="bg-gradient-to-t flex justify-center from-[#04022E] to-[#04022E] h-full w-full rounded-full">
                        <h2
                          class="my-auto text-[#01CCFF] font-black text-center text-[32px] leading-[38px] lg:text-[48px] lg:leading-[58px]"
                          style="font-family: Lato, sans-serif">
                          2nd
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col items-center justify-center w-full mt-4 lg:mt-9">
                    <p class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text lg:text-base text-sm font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                      style="font-family: Russo One, system-ui">
                      {{
                        top3.length > 0
                          ? getMaskedUsername(top3[1]?.username)
                          : ""
                      }}
                    </p>
                    <p class="text-white text-lg lg:text-[32px] lg:leading-[38px] uppercase mt-3 lg:mt-8 font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                      style="font-family: Lato, sans-serif">
                      {{ top3.length > 0 ? top3[1]?.weightedWagered.toFixed(2) : "" }}
                    </p>
                    <p class="mt-1 text-sm font-bold text-white uppercase lg:text-base"
                      style="font-family: Lato, sans-serif">
                      Wager
                    </p>
                    <div class="mt-[29px] lg:mt-[43px] relative px-[26px] py-1 lg:py-2 lg:px-12 xl:px-16 w-full">
                      <img src="../assets/prize-button.png" class="absolute top-0 left-0 w-full h-[43px] md:h-[72px]"
                        alt="Reward">
                      <p class="text-[#01CCFF] font-black text-2xl md:mt-4 lg:mt-0 lg:text-[48px] lg:leading-[58px] text-center"
                        style="font-family: Lato, sans-serif">
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-motion-pop-visible
              class="absolute flex flex-col items-center border-t border-b border-solid md:h-[260px] lg:h-full lg:-mt-40 top-2 lg:relative"
              style="box-shadow: -4px -4px 30px 3px rgba(154, 54, 248, 1), 4px 4px 30px 3px rgba(154, 54, 248, 1); border-image: linear-gradient(to left, #01CCFF, #9A36F8) 1;">
              >
              <div
                class="p-2.5 pb-[13px] md:pb-1 bg-gradient-to-r rounded-none w-[150px] md:w-[200px] lg:w-[280px] xl:w-[325px] bg-opacity-5">
                <div class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl">
                  <div
                    class="bg-gradient-to-tl w-[70px] lg:w-[146px] from-[#01CCFF] to-[#9A36F8] -mt-[78px] lg:-mt-[120px] -ml-10 h-[70px] lg:h-[146px]  rounded-full overflow-hidden top-0 translate-x-4">
                    <div
                      class="absolute inset-0 m-[2px] bg-[#04022E] flex items-center justify-center overflow-hidden rounded-full">
                      <div
                        class="bg-gradient-to-t flex justify-center from-[#04022E] to-[#04022E] h-full w-[60px] rounded-full ">
                        <h2
                          class="my-auto text-[#01CCFF] font-black text-center text-[32px] leading-[38px] lg:text-[48px] lg:leading-[58px]"
                          style="font-family: Lato, sans-serif">
                          1st
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col items-center justify-center w-full mt-4 lg:mt-9">
                    <p class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text lg:text-base text-sm font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                      style="font-family: Russo One, system-ui">
                      {{
                        top3.length > 0
                          ? getMaskedUsername(top3[0]?.username)
                          : ""
                      }}
                    </p>
                    <p class="text-white text-lg lg:text-[32px] lg:leading-[38px] uppercase mt-3 lg:mt-8 font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                      style="font-family: Lato, sans-serif">
                      {{ top3.length > 0 ? top3[0]?.weightedWagered.toFixed(2): "" }}
                    </p>
                    <p class="mt-1 text-sm font-bold text-white uppercase lg:text-base"
                      style="font-family: Lato, sans-serif">
                      Wager
                    </p>
                    <div class="mt-[29px] lg:mt-[43px] relative px-[26px] py-1 lg:py-2 lg:px-2 w-full">
                      <img src="../assets/prize-button.png" class="absolute top-0 left-0 w-full h-[43px] md:h-[72px]"
                        alt="Reward">
                      <p class="text-[#01CCFF] font-black text-2xl md:mt-4 lg:mt-0 lg:text-[48px] lg:leading-[58px] text-center"
                        style="font-family: Lato, sans-serif">
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-motion-pop-visible
              class="flex flex-col items-center mt-28 md:h-[260px] lg:h-full md:mt-44 lg:mt-[88px] border-t border-b border-solid"
              style="box-shadow: -4px -4px 15px 3px rgba(154, 54, 248, 1), 4px 4px 15px 3px rgba(154, 54, 248, 1); border-image: linear-gradient(to left, #01CCFF, #9A36F8) 1;">
              >
              <div
                class="p-2.5 pb-[13px] md:pb-1 bg-gradient-to-r rounded-none w-[150px] md:w-[200px] lg:w-[280px] xl:w-[325px] bg-opacity-5">
                <div class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl">
                  <div
                    class="bg-gradient-to-tl w-[70px] lg:w-[146px] from-[#01CCFF] to-[#9A36F8] -mt-[78px] lg:-mt-[120px] -ml-10 h-[70px] lg:h-[146px] rounded-full overflow-hidden top-0 translate-x-4">
                    <div
                      class="absolute inset-0 m-[2px] bg-[#04022E] flex items-center justify-center overflow-hidden rounded-full">
                      <div
                        class="bg-gradient-to-t flex justify-center from-[#04022E] to-[#04022E] h-full w-full rounded-full">
                        <h2
                          class="my-auto text-[#01CCFF] font-black text-center text-[32px] leading-[38px] lg:text-[48px] lg:leading-[58px]"
                          style="font-family: Lato, sans-serif">
                          3rd
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col items-center justify-center w-full mt-4 lg:mt-9">
                    <p class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text lg:text-base text-sm font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                      style="font-family: Russo One, system-ui">
                      {{
                        top3.length > 0
                          ? getMaskedUsername(top3[2]?.username)
                          : ""
                      }}
                    </p>
                    <p class="text-white text-lg lg:text-[32px] lg:leading-[38px] uppercase mt-3 lg:mt-8 font-bold overflow-hidden max-w-[130px] md:max-w-full text-ellipsis whitespace-nowrap"
                      style="font-family: Lato, sans-serif">
                      {{ top3.length > 0 ? top3[2]?.weightedWagered.toFixed(2) : "" }}
                    </p>
                    <p class="mt-1 text-sm font-bold text-white uppercase lg:text-base"
                      style="font-family: Lato, sans-serif">
                      Wager
                    </p>
                    <div class="mt-[29px] lg:mt-[43px] relative px-[26px] py-1 lg:py-2 lg:px-12 xl:px-16 w-full">
                      <img src="../assets/prize-button.png" class="absolute top-0 left-0 w-full h-[43px] md:h-[72px]"
                        alt="Reward">
                      <p class="text-[#01CCFF] font-black text-2xl md:mt-4 lg:mt-0 lg:text-[48px] lg:leading-[58px] text-center"
                        style="font-family: Lato, sans-serif">
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex justify-center mt-[80px] lg:mt-[120px] mb:mb-24 bg-transparent mx-auto max-w-[1280px] px-6 md:px-14">
            <table
              class="w-full h-[800px] border-[1px] border-[#04022E] lg:w-full bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E] bg-opacity-5"
              style="box-shadow: -4px -4px 30px 3px rgba(154, 54, 248, 0.5), 4px 4px 30px 3px rgba(154, 54, 248, 0.5); border-image: linear-gradient(to left, #FF252F, #A62AFF) 1;">
              <thead v-motion-slide-visible-bottom class="bg-transparent " style="font-family: Lato, sans-serif">
                <tr>
                  <th
                    class="text-center px-5 lg:px-[45px] xl:pr-28 xl:pl-12 py-2 md:py-4 text-[#01CCFF] text-[18px] leading-6 font-black md:text-left rounded-l w-1/4">
                    RANK
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-24 py-2 md:py-4 text-[#01CCFF] text-[18px] leading-6 font-black text-center w-1/4">
                    USER
                  </th>
                  <th
                    class="px-0 md:px-5 lg:px-[45px] xl:px-24 py-2 md:py-4 text-[#01CCFF] text-[18px] leading-6 font-black text-center w-1/4">
                    WAGERED
                  </th>
                  <th
                    class="px-4 text-right lg:px-[45px] xl:pl-28 xl:pr-12 py-2 md:py-4 text-[#01CCFF] text-[18px] leading-6 font-black rounded-r w-1/4">
                    PRIZE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index"
                  style="font-family: Lato, sans-serif" class="bg-transparent rounded">
                  <td
                    class="px-5 text-center lg:px-[45px] xl:px-28 xl:pl-12 py-2 md:py-4 whitespace-nowrap text-base font-normal md:text-left uppercase w-1/4">
                    <div class="text-white tracking-[4px]">
                      {{ getOrdinalSuffix(index + 4) }}
                    </div>
                  </td>
                  <td
                    class="px-2 md:px-5 max-w-[92px] overflow-hidden text-ellipsis md:max-w-full py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px] w-1/4">
                    {{ getMaskedUsername(item?.username) }}
                  </td>
                  <td
                    class="px-1 md:px-5 max-w-[92px] py-2 md:py-5 whitespace-nowrap text-ellipsis overflow-hidden text-base text-white font-normal text-center tracking-[4px] w-1/4">
                    {{ item?.weightedWagered.toFixed(2) }}
                  </td>
                  <td
                    class="px-4 text-center md:px-5 lg:px-[45px] xl:px-28 xl:pr-12 py-2 md:py-5 whitespace-nowrap text-base text-white font-black md:text-right tracking-[4px] w-1/4">
                    {{ getRewardValue(index + 3) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';
import axios from "axios";
// import { STAKE_CURRENT_PRIZES, STAKE_PREVIOUS_PRIZES } from "../../const";
import moment from "moment-timezone";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  name: "LeaderboardSection",
  data() {
    return {
      top3: [],
      topRest: [],
      timer: "",
      topForMobile: [],
      selectedLeaderboard: false,
      activeTabImage: require("../assets/tab-active.png"),
      inActiveTabImage: require("../assets/tab-inactive.png"),
      currentLeaderboard: [],
      prevLeaderboard: [],

      startDate: null,
      endDate: null,

      notFormattedEndDate: null,

      loading: false,
    };
  },
  watch: {
    selectedLeaderboard(newSelected) {
      if (newSelected) {
        this.activeTabImage = require("../assets/tab-inactive.png")
        this.inActiveTabImage = require("../assets/tab-active.png")
      } else {
        this.activeTabImage = require("../assets/tab-active.png")
        this.inActiveTabImage = require("../assets/tab-inactive.png")
      }
    },
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;
      await axios
        .get(`${baseUrl}/api/leaderboard/roobet`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        })
        .then((res) => {
          axios
            .get(`${baseUrl}/api/leaderboard/roobet?previous=true`, {
              headers: {
                "x-api-key": process.env.VUE_APP_X_API_KEY,
              },
            })
            .then((rest) => {
              // const startDateSplit = res.data.startDate.split('-');
              // const endDateSplit = res.data.endDate.split('-');
              this.startDate = moment(res.data.startDate).format(
                "MMMM DD, YYYY"
              );
              this.endDate = moment(res.data.endDate).format("MMMM DD, YYYY");
              console.log(this.endDate, "end date");
              this.notFormattedEndDate = res.data.endDate;
              this.currentLeaderboard = res.data.leaderboard;
              this.prevLeaderboard = rest.data.leaderboard;
              this.changeLeaderboard();
              this.startTimer();
              this.loading = false;
              console.log(res.data, "res data");
            });
        });
    },
    changeLeaderboard() {
      if (this.selectedLeaderboard == false) {
        const leaderboard = this.currentLeaderboard;
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.topRest = leaderboard.slice(3, 10).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 10).map((item) => {
          return item;
        });
      } else {
        const leaderboard = this.prevLeaderboard;
        console.log(leaderboard, "prev leaderboard");
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        console.log(this.top3, "top3");

        this.topRest = leaderboard.slice(3, 10).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 10).map((item) => {
          return item;
        });
      }
    },
    getMaskedUsername(username) {
      if (username.length <= 6) {
        return username;
      }
      return (
        username.substring(0, 3) +
        "*****" +
        username.substring(username.length - 3)
      );
    },
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRewardValue(rank) {
  const prizes = JSON.parse(
    this.selectedLeaderboard
      ? process.env.VUE_APP_PREVIOUS_PRIZES
      : process.env.VUE_APP_CURRENT_PRIZES
  );

  return prizes[rank];
},
    startTimer() {
      const getNextTargetDate = () => {
        const now = moment().utc();
        let targetDate = moment(`${this.notFormattedEndDate}`)
          .utc()
          .subtract(1, "seconds");

          console.log(targetDate)
        if (now.isAfter(targetDate)) {
          targetDate.add(1, "M");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment.tz("UTC");
        const distance = targetDate.diff(nowEst);
        // console.log("distance", distance);
        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.timer = "Leaderboard ended";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },
    isMobile() {
      return window?.innerWidth < 768; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.toggle-switch {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 632px;
  height: 72px;
  border: 2px #2cceee;
  /* background: linear-gradient(to right, #121212, #461B83 , #121212); */
  border-radius: 20px;
  padding: 0 8px;
  cursor: pointer;
  

  @media screen and (max-width: 640px) {
    width: 300px;
    height: 74px;
  }
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  
}

.toggle-switch .slider {
  position: absolute;
  left: 0;
  top: 2px;
  border-radius: 10px;
  width: 300px;
  height: 72px;
  background: url("../assets/tab-active.png") no-repeat;
  background-color: #00C3FF;
  /* border-radius: 24px; */
  transition: transform 0.3s ease;
  box-shadow: -4px -4px 15px 3px rgba(0, 195, 255, 0.5), 4px 4px 15px 3px rgba(0, 178, 209, 0.5);
}

.toggle-switch input:checked + .slider {
  transform: translateX(330px);

  @media screen and (max-width: 640px) {
    transform: translateY(100px);
  }
}

.toggle-switch span {
  font-size: 35px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;

}

.toggle-switch .left-label {
  position: absolute;
  left: 0px;
  margin-top: 5px;
  font-weight: 50;
  

  @media screen and (max-width: 640px) {
    top: 0;
    /* left: 100px; */
  }
}

.toggle-switch .right-label {
  position: absolute;
  right: 2px;
  margin-top: 5px;
  font-weight: 50;
  @media screen and (max-width: 640px) {
    bottom: -80px;
    /* left: 100px; */
  }
}

.custom-border {
  opacity: 1;
  margin: 10px;
  border: solid 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#090B2A, #090B2A), 
                    linear-gradient(to bottom, #ED3C80, #3E6B3E);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  /* background: url('../assets/tab-active.png') no-repeat; rgba(159,84,249,0) */
}
</style>