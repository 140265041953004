<template>
  <div id="socials" class="relative overflow-hidden pt-[180px] pb-[171px] md:pt-[40px] md:pb-[158px] bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E]">

      <div class="text-[32px] md:text-5xl lg:text-5xl text-white leading-10 font-black [text-shadow:4px_10px_4px_#00F0FF33] text-center font-montserrat">
     FOLLOW MY <span class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text">SOCIALS</span>
      </div>

      <div
    class="flex flex-wrap flex-row justify-center gap-[20px] sm:gap-[40px] md:gap-[60px] mt-[40px] sm:mt-[70px] md:mt-[100px]"
  >
      <!-- X Button -->
      <a
      v-motion-slide-visible-bottom
      href="https://x.com/IFugroo"
      target="_blank"
      class="shadow-[0_0_50px_#9A36F8] rounded-full sm:h-[120px] sm:w-[120px] h-[100px] w-[100px] md:h-[140px] md:w-[140px] hover:cursor-pointer relative"
    >
      <img
        src="../assets/social-button.png"
        alt="x button"
        class="object-cover w-full h-full rounded-full"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          src="../assets/X.png"
          class="object-contain w-12 h-12 sm:w-8 sm:h-8 md:w-14 md:h-14"
          alt="X"
        />
      </div>
    </a>

    <!-- YouTube Button -->
    <a
      v-motion-slide-visible-bottom
      href="https://www.youtube.com/@HanveeStreamer"
      target="_blank"
      class="shadow-[0_0_50px_#9A36F8] rounded-full sm:h-[120px] sm:w-[120px] h-[100px] w-[100px] md:h-[140px] md:w-[140px] hover:cursor-pointer relative"
    >
      <img
        src="../assets/social-button.png"
        alt="youtube button"
        class="object-cover w-full h-full rounded-full"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          src="../assets/youtube.png"
          class="object-contain w-12 h-12 sm:w-8 sm:h-8 md:w-14 md:h-14"
          alt="youtube"
        />
      </div>
    </a>

    <!-- Discord Button -->
    <a
      v-motion-slide-visible-bottom
      href="https://discord.gg/N4UMQS3aNN"
      target="_blank"
      class="shadow-[0_0_50px_#9A36F8] rounded-full sm:h-[120px] sm:w-[120px] h-[100px] w-[100px] md:h-[140px] md:w-[140px] hover:cursor-pointer relative"
    >
      <img
        src="../assets/social-button.png"
        alt="discord button"
        class="object-cover w-full h-full rounded-full"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          src="../assets/discord.png"
          class="object-contain w-12 h-12 sm:w-8 sm:h-8 md:w-14 md:h-14"
          alt="discord"
        />
      </div>
    </a>

    <!-- Kick Button -->
    <a
      v-motion-slide-visible-bottom
      href="https://kick.com/fugroo"
      target="_blank"
      class="shadow-[0_0_50px_#9A36F8] rounded-full sm:h-[120px] sm:w-[120px] h-[100px] w-[100px] md:h-[140px] md:w-[140px] hover:cursor-pointer relative"
    >
      <img
        src="../assets/social-button.png"
        alt="kick button"
        class="object-cover w-full h-full rounded-full"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          src="../assets/kick.png"
          class="object-contain w-12 h-12 sm:w-8 sm:h-8 md:w-14 md:h-14"
          alt="kick"
        />
      </div>
    </a>

    <!-- Instagram Button -->
    <a
      v-motion-slide-visible-bottom
      href="#"
      target="_blank"
      class="shadow-[0_0_50px_#9A36F8] rounded-full sm:h-[120px] sm:w-[120px] h-[100px] w-[100px] md:h-[140px] md:w-[140px] hover:cursor-pointer relative"
    >
      <img
        src="../assets/social-button.png"
        alt="instagram button"
        class="object-cover w-full h-full rounded-full"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          src="../assets/insta.png"
          class="object-contain w-12 h-12 sm:w-8 sm:h-8 md:w-14 md:h-14"
          alt="instagram"
        />
      </div>
    </a>

     <!-- tiktok Button -->
     <a
      v-motion-slide-visible-bottom
      href="#"
      target="_blank"
      class="shadow-[0_0_50px_#9A36F8] rounded-full sm:h-[120px] sm:w-[120px] h-[100px] w-[100px] md:h-[140px] md:w-[140px] hover:cursor-pointer relative"
    >
      <img
        src="../assets/social-button.png"
        alt="tiktok button"
        class="object-cover w-full h-full rounded-full"
      />
      <div class="absolute inset-0 flex items-center justify-center">
        <img
          src="../assets/tiktok.png"
          class="object-contain w-12 h-12 sm:w-8 sm:h-8 md:w-14 md:h-14"
          alt="tiktok"
        />
      </div>
    </a>
  </div>
  </div>
</template>

<script>

export default {
  name: "SocialSection",
};
</script>

