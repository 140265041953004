<template>
  <!-- Footer start -->
  <footer class="relative w-full h-full px-4 py-10 bg-[#01001D] lg:py-20">
    <div class="flex flex-col lg:px-8 xl:px-20 max-w-[1400px] sm:max-w-[600px] md:max-w-[900px] lg:max-w-[1400px] mx-auto">
      <!-- Main Row: Logo, Navigation Links, Social Icons -->
      <div class="flex flex-col items-center justify-between w-full gap-6 lg:flex-row">
        <!-- Logo Section -->
        <div class="flex items-center gap-2">
          <!-- <a @click="showMenu = false; this.isScrolled = false;" href="">
            <img v-motion-pop-visible alt="logo" src="@/assets/nav-logo.png" />
          </a> -->
          <a href="#home" class="flex items-center justify-start gap-x-2 sm:gap-x-4"><img v-motion-pop-visible src="@/assets/fugroo-logo.png" class="w-auto h-[52px] object-contain" alt="logo"></a>
        </div>

        <!-- Navigation Links -->
        <div v-motion-pop-visible
          class="flex flex-wrap justify-center gap-4 text-lg font-extrabold text-white uppercase lg:flex-nowrap font-lato">
          <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" :class="[
            'block w-full text-center px-4 py-2  transition-colors lg:inline-block lg:w-auto',
            {
              'font-extrabold text-[#1EC3FF]': activeSection === item.id,
              'text-white hover:text-[#e4dfdf]': activeSection !== item.id

            }
          ]">
            {{ item.name }}
          </a>

        </div>

        <!-- Social Icons + Copyright -->
        <div class="flex flex-col items-center gap-0">
          <!-- Empty space with consistent height -->
          <p class="h-4 text-sm font-bold font-oxanium" style="visibility: hidden;">
            &nbsp; <!-- Non-breaking space to ensure it renders -->
          </p>

          <!-- Icons Row -->
          <div v-motion-pop-visible class="flex flex-row items-center justify-center gap-1.5">
            <a href="/" target="_blank">
              <img src="@/assets/youtube-footer.png" class="h-[48px] w-[34px]" />
            </a>
            <a href="https://x.com/IFugroo" target="_blank">
              <img src="@/assets/x-footer.png" class="h-[26px] w-[26px]" />
            </a>
            <a href="https://discord.gg/N4UMQS3aNN" target="_blank">
              <img src="@/assets/discord.png" class="h-[25px] w-[25px] object-contain" />
            </a>
            <a href="https://kick.com/fugroo" target="_blank">
              <img src="@/assets/kick-footer.png" class="h-[25px] w-[25px]" />
            </a>
            <a href="#" target="_blank">
              <img src="@/assets/instagram-footer.png" class="h-[25px] w-[25px] object-contain" />
            </a>
            <a href="#" target="_blank">
              <img src="@/assets/tiktok-footer.png" class="h-[25px] w-[25px] object-contain" />
            </a>
          </div>
        </div>

      </div>

      <!-- Centered Paragraphs Section -->
      <div class="flex flex-col items-center w-full gap-4 px-4 py-4 mt-4 text-base font-normal text-center lg:px-0 font-lato">
        <p class="text-white">
          If you ever feel that gambling is becoming a problem, we urge you to
          seek help and support. Most people gamble for fun and enjoyment.
          However, some people think of gambling as a way to make money, spend
          more than they can afford, or use gambling to distract themselves from
          everyday problems.
        </p>
        <p class="text-[#1EC3FF] font-bold font-oxanium">
          To find further advice, help and support visit:
          <span class="underline text-inherit"> <a href=" https://www.begambleaware.org/" target="_blank">
              https://www.begambleaware.org/
            </a></span>
        </p>
        <p class="text-white">
          We do not take responsibility for misunderstood promotions, losses
          from gambling in casinos or betting on sites which are linked to or
          promoted on this website. Make sure it is legal for you to use each
          site inside your jurisdiction and ALWAYS gamble responsibly!
        </p>
      </div>
    </div>
  </footer>
  <!-- Footer end -->
</template>


<script>
export default {
  name: 'FooterElement',
  props: {
    activeSection: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      navItems: [
        { id: 'home', name: 'Home' },
        { id: 'promotions', name: 'Promotions' },
        { id: 'socials', name: 'Socials' },
        { id: 'leaderboards', name: 'Leaderboards' },
      ]
    };
  },
}
</script>