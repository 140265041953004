<template>
	<!-- Navbar start -->
	<NavbarElement :activeSection="activeSection" />
	<!-- Navbar end -->

	<router-view @sections-ready="setupIntersectionObserver"></router-view>

	<!-- Footer start -->
	<FooterElement :activeSection="activeSection" />
	<!-- Footer end -->
</template>

<script>
import axios from 'axios';
import NavbarElement from '@/components/NavbarElement.vue';
import FooterElement from '@/components/FooterElement.vue';
// import LoadingElement from '@/components/LoadingElement.vue';
export default {
	components: {
		FooterElement,
		NavbarElement,
		// LoadingElement
	},
	data() {
		return {
			activeSection: 'home',
			observer: null,
			// loading: false
		}
	},
	mounted() {
		const code = this.$route.query.code;
		if (code != null) {
			this.loading = true
			let formData = {
				code: code
			};
			axios.post(process.env.VUE_APP_SERVER_URL + '/auth/twitchLogin', formData, { headers: { 'Content-Type': 'application/json' } }).then((res) => {
				if (res) {
					localStorage.setItem("oeg-access-token", res.data.accessToken)
					this.$store.commit('UPDATE_USER', res.data.accessToken)
					this.loading = false
					// this.$router.replace("/profile")
				}
			})
		}

	},
	methods: {
		setupIntersectionObserver() {
			this.observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.activeSection = entry.target.id;
					}
				});
			}, { threshold: 0.2 });

			['home', 'socials', 'leaderboards', 'promotions', 'rewards', 'videos'].forEach((id) => {
				const element = document.getElementById(id);
				if (element) this.observer.observe(element);
			});
		}
	},
	beforeUnmount() {
		if (this.observer) {
			['home', 'socials', 'leaderboards', 'promotions', 'rewards', 'videos'].forEach((id) => {
				const element = document.getElementById(id);
				if (element) this.observer.unobserve(element);
			});
		}
	}
}
</script>
