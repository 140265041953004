<template>
  <div class="relative overflow-y-hidden bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E]">

    <section
      class="h-full overflow-hidden max-h-[2220px] sm:max-h-[2220px] md:max-h-[2300px] xl:max-h-[2300px] relative bg-gradient-to-r from-[rgba(125, 81, 163, 0)] to-[rgba(68, 76, 152, 1)]"
      style="background: linear-gradient(to right, rgba(68, 76, 152, 1), rgba(68, 76, 152, 1), rgba(68, 76, 152, 1));"
      id="home">

      <img src="../assets/circle.png" alt="Circle 1"
        class="hidden md:block absolute bottom-[20px] right-[120px] md:-right-[60px] w-[320px] h-[320px] rounded-full object-contain">
      <img src="../assets/circle.png" alt="Circle 2"
        class="hidden md:block absolute bottom-[200px] -left-[100px] w-[440px] h-[440px] rounded-full object-contain">
      <!-- Background Image -->
      <!-- class="absolute inset-0 object-cover w-full h-full bg-center bg-repeat-y -z-10"  -->
      <img src="@/assets/hero-bg.png"
        class="absolute inset-0 z-10 block object-cover object-bottom w-full h-full bg-repeat-y xl:hidden xl:max-h-full xl:object-contain xl:object-top"
        alt="background-image" />
      <img src="@/assets/hero-bg-xl.png"
        class="absolute inset-0 z-10 hidden object-cover object-bottom w-full h-full bg-repeat-y xl:block xl:max-h-full xl:object-cover xl:object-top"
        alt="background-image" />

      <!-- Content Container -->
      <div
        class="z-30 flex flex-col items-center justify-center w-full px-10 pt-20 sm:px-14 sm:pt-20 md:pt-20 lg:pt-[70px] 2xl:pt-28 lg:px-40 xl:px-72 xl:pt-20 2xl:px-80">
        <!-- Hero Image (Responsive Display) -->
        <div class="flex flex-col items-center justify-center">

          <!-- Desktop Image -->
          <img v-motion-pop-visible src="@/assets/hero-fugroo.png"
            class="z-20 block w-[200px] sm:w-[50%] md:w-[360px] lg:w-[360px] 2xl:w-[44%] mt-10" alt="desktop-title-text" />

          <img v-motion-pop-visible src="@/assets/hero-text.png" class="z-20 block w-[400px] sm:w-[600px] md:w-[700px] lg:w-[800px] xl:w-[900px] mt-4"
            alt="desktop-title-text" />

               <!-- Button section -->
          <div v-motion-pop-visible
            class="flex flex-nowrap sm:flex-row flex-col justify-center items-center gap-4 lg:gap-12 mt-8 z-20 mb-[86px] md:mb-[122px]">
            <div class="relative shadow-[0_0_50px_#9A36F8] h-[72px] w-[300px] rounded-lg bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] 
    hover:shadow-[0_0_8px_4px_#01CCFF] transition-shadow duration-200">
              <div class="absolute inset-0 m-[2px] rounded-lg bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] 
      overflow-hidden flex items-center justify-center">
                <a href="https://roobet.com/?ref=fugroo"
                  class="z-10 py-6 font-bold text-center text-white px-14 text-md whitespace-nowrap"
                  style="font-family: Lato, sans-serif;">
                  REGISTER ON ROOBET.COM NOW!
                </a>
                <img src="@/assets/hero-btn-style-5.png" alt="Decorative slant shadow"
                  class="absolute inset-y-0 left-0 h-full pointer-events-none" />
                <img src="@/assets/hero-btn-style-6.png" alt="Decorative slant shadow"
                  class="absolute inset-y-0 right-0 h-full pointer-events-none" />
              </div>
            </div>

         
            <div class="relative shadow-[0_0_50px_#9A36F8] h-[72px] w-[300px] rounded-lg 
         bg-black bg-opacity-10 border-[2px] border-[#00C3FF]
            hover:shadow-[0_0_8px_4px_#00C3FF] transition-shadow duration-200">
              <div class="absolute inset-0 m-[2px] rounded-lg bg-transparent
                overflow-hidden flex items-center justify-center">
                <a href="https://kick.com/fugroo"
                  class="z-10 py-6 font-bold text-center text-white px-14 text-md whitespace-nowrap"
                  style="font-family: Lato, sans-serif;">
                  WATCH LIVE STREAM ON KICK
                </a>
                <img src="@/assets/hero-btn-style-5.png" alt="Decorative slant shadow"
                  class="absolute inset-y-0 left-0 h-full pointer-events-none" />
                <img src="@/assets/hero-btn-style-6.png" alt="Decorative slant shadow"
                  class="absolute inset-y-0 right-0 h-full pointer-events-none" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <PromotionSection />
    <SocialSection />
    <LeaderboardSection />
    <RewardsSection />
    <VideosSection />
  </div>
</template>

<script>
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import PromotionSection from "@/sections/PromotionSection.vue";
import SocialSection from "@/sections/SocialSection.vue";
import RewardsSection from "@/sections/RewardsSection.vue";
import VideosSection from "@/sections/VideosSection.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    PromotionSection,
    LeaderboardSection,
    SocialSection,
    RewardsSection,
    VideosSection
  },
  mounted() {
    this.$emit("sections-ready");
  },
});
</script>
