<template>
    <!-- Videos section -->
    <div id="videos"
          class="z-20 flex flex-col relative px-6 items-center justify-between w-full h-full pb-[200px] mt-20 gap-y-6 md:gap-y-0 gap-x-6 lg:gap-x-12 bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E]">

          <img src="../assets/videos-bg.png"
          class="absolute top-0 left-0 z-10 object-cover object-center w-full h-full" />
          
          <div class="text-[32px] md:text-7xl lg:text-4xl text-white leading-10 z-30  font-black text-center font-montserrat">
 WATCH MY <span class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text">STREAMS</span> 
          </div>

          <!-- Left Section -->
          <div class="flex flex-col z-30 items-start justify-center md:w-[80%] lg:w-full gap-8 lg:flex-row-reverse mt-10 md:mt-20">
            <!-- Live banner mobile -->
            <div v-motion-slide-visible-left
              style="box-shadow: -4px -4px 15px 3px rgba(1, 204, 255, 1), 4px 4px 15px 3px rgba(154, 54, 248, 1); border-image: linear-gradient(to left, #01CCFF, #9A36F8) 10;"
              class="flex flex-col items-center w-full text-center rounded-lg gap-y-2 bg-opacity-20 backdrop-blur-lg lg:hidden bg-[url('@/assets/live-banner-bg.png')] bg-cover bg-center bg-no-repeat p-4">
              <img src="@/assets/hero-fugroo.png" alt="live-banner-logo" class="w-1/2">
              <div class="flex flex-col mt-4 gap-y-2">
                <h1 class="text-xl font-bold text-white">PLAY WHERE I PLAY!</h1>
                <h2 class="text-lg font-bold text-white ">LIVE EVERYDAY<span></span> @ 7:30 PM GMT </h2>
              </div>
            </div>

            <!-- Live banner desktop -->
            <div v-motion-slide-visible-left
          style="box-shadow: -4px -4px 15px 3px rgba(1, 204, 255, 1), 4px 4px 15px 3px rgba(154, 54, 248, 1); border-image: linear-gradient(to left, #01CCFF, #9A36F8) 10;"
              class="flex-col items-center justify-center h-full py-6 xl:py-[42px] 2xl:py-[45px]  w-[300px] text-center rounded-lg gap-y-2 bg-opacity-20 backdrop-blur-lg hidden lg:flex bg-[url('@/assets/live-banner-bg.png')] bg-cover bg-center bg-no-repeat">
              <img src="@/assets/hero-fugroo.png" alt="live-banner-logo" class="w-[80%]">
              <div class="flex flex-col mt-4 gap-y-2">
                <h1 class="text-white text-[2vw] xl:text-xl 2xl:text-2xl">PLAY WHERE I PLAY!</h1>
                <h2 class="text-white text-[1.1vw] xl:text-xs 2xl:text-sm ">LIVE EVERYDAY<span></span> @ 7:30 PM GMT
                </h2>
              </div>
            </div>
            <!-- Live Stream Section -->
            <video
        src="../assets/socials-video.mp4"
        class="w-full lg:w-[700px] xl:w-[740px] h-full"
        autoplay
        loop
        muted
        playsinline
        alt="socials-video"
        v-motion-pop-visible
      ></video>
          </div>
        </div>
  </template>
  
  