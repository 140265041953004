<template>
    <!-- Rewards section -->
    <div  id="rewards"
          class="z-20 flex flex-col items-center justify-between w-full px-4 pb-20 mt-36 gap-y-6 md:gap-y-0 gap-x-6 lg:gap-x-12">

          <div v-motion-pop-visible class="text-[32px] md:text-7xl lg:text-4xl text-white leading-10  font-black text-center font-montserrat">
          RANK UP <span class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text">REWARDS</span> 
          </div>

          <div class="flex flex-wrap items-center justify-center w-full mt-20 gap-y-6 gap-x-10">

          <img
          v-motion-pop-visible
        src="../assets/reward-card1.png"
        alt="x button"
        class="object-contain w-[120px] h-[120px] md:w-[160px] md:h-[160px] "
      />
      <img
      v-motion-pop-visible
        src="../assets/reward-card2.png"
        alt="x button"
        class="object-contain w-[120px] h-[120px] md:w-[160px] md:md:h-[160px]"
      />
      <img
      v-motion-pop-visible
        src="../assets/reward-card3.png"
        alt="x button"
        class="object-contain w-[120px] h-[120px] md:w-[160px] md:h-[160px]"
      />
      <img
      v-motion-pop-visible
        src="../assets/reward-card4.png"
        alt="x button"
        class="object-contain w-[120px] h-[120px] md:w-[160px] md:h-[160px]"
      />
      <img
      v-motion-pop-visible
        src="../assets/reward-card5.png"
        alt="x button"
        class="object-contain w-[120px] h-[120px] md:w-[160px] md:h-[160px]"
      />
      <img
      v-motion-pop-visible
        src="../assets/reward-card6.png"
        alt="x button"
        class="object-contain w-[120px] h-[120px] md:w-[160px] md:h-[160px]"
      />
    </div>
    <div class="flex justify-center mt-[200px] lg:mt-[240px] mb-24 bg-transparent mx-auto w-full max-w-[1180px] sm:px-10">
  <div
    class="flex flex-col w-full h-full pb-10 border-[1px] border-[#04022E] lg:w-full bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E] bg-opacity-5"
    style="box-shadow: -4px -4px 30px 3px rgba(154, 54, 248, 0.5), 4px 4px 30px 3px rgba(154, 54, 248, 0.5);">
    <div class="flex flex-col items-center justify-center p-10">
      <h1 class="text-xl font-black text-white md:text-4xl font-montserrat"> VIP TRANSFER <span class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text"> BONUS </span></h1>
    </div>
    <div class="p-8 mt-6">
    <p class="flex flex-col mb-8 text-lg text-center text-white md:text-start font-montserrat ">
      <span class="font-black">What are VIP transfers?</span><br>
      <span class=" md:ml-10">
        VIP transfer is a term commonly used in the realm of gambling websites like Roobet. It refers to a process where users are granted special privileges or bonuses upon becoming VIP players.
      </span>
    </p>
    <p class="flex flex-col mb-8 text-lg text-center text-white md:text-start font-montserrat">
      <span class="font-black">How do VIP transfers to Roobet work?</span><br>
      <span class="md:ml-10 ">
        Play on Roobet and over a 3-week period of enjoying Roobet’s generous bonuses, Roobet evaluates players for potential VIP status based on their activity and engagement. If deemed worthy, selected users will receive personal contact from a VIP manager, who will provide details about Roobet’s VIP program and extend an invitation to join.
      </span>
    </p>
    <p class="flex flex-col mb-8 text-lg text-center text-white md:text-start font-montserrat">
      <span class="font-black">What are the requirements?</span><br>
      <span class="md:ml-10 ">
        You must have a Roobet account under code “<strong>Fugroo</strong>”.<br>
        You must have VIP on another valid gambling website.
      </span>
    </p>
    <p class="flex flex-col text-lg text-center text-white md:text-start font-montserrat">
      <span class="font-black">What's the purpose of transferring my VIP status to Roobet?</span><br>
      <span class="md:ml-10 ">
        Roobet provides amazing bonuses starting within your first 3 weeks of play and then continuing to progress once getting into VIP. You can compete in the “<strong>Fugroo</strong>” Roobet leaderboard every month.<br><br>
      </span>
      <span class="font-black">  Roobet provides the best rewards in the long run.</span>
    </p>
  </div>
  </div>
</div>
</div>
  </template>
  
  