<template>
  <section
    id="promotions"
    class="relative overflow-hidden z-10 h-full pt-24 pb-48 sm:pb-64 md:pb-72 lg:pb-[200px] bg-gradient-to-r from-[#04022E] via-[#04022E] to-[#04022E]"
  >

    <div class="relative px-4 mx-auto sm:max-w-4xl lg:max-w-6xl 2xl:max-w-7xl">
      <div class="flex flex-col items-center justify-center gap-2">
        <h2
          v-motion-pop-visible
          class="mb-6 text-4xl font-extrabold [text-shadow:4px_10px_4px_#00F0FF33] text-center text-white md:text-5xl font-montserrat"
        >
          MY <span
    class="bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] text-transparent bg-clip-text"
  >
    PROMOTIONS
  </span>
        </h2>
      </div>

      <!-- Promo Card -->
      <div  v-motion-pop-visible class="flex flex-col items-center justify-center w-full mt-10">
        <div style="box-shadow: 0 0 50px #9A36F8;"
          class="relative z-20 flex flex-col items-center justify-center lg:justify-start lg:items-start w-[2000px] max-w-[340px] h-[560px] lg:max-w-[94%] xl:max-w-[1100px] lg:h-[360px] rounded-[10px] pb-10 lg:pt-0 overflow-hidden text-center bg-center bg-no-repeat bg-fill lg:pb-0 lg:bg-cover bg-promo-bg-sm lg:bg-promo-bg"
        >
          <!-- Content -->
          <div class="relative z-10 md:pt-4 lg:pt-8 lg:px-10 xl:px-14">

            <!-- Promo Details -->
            <div class="flex flex-col mt-6 md:gap-8 lg:gap-0 lg:mt-0">
              <div class="flex flex-col items-start md:gap-x-6 w-full lg:w-[500px]">
                <div class="flex flex-col items-center justify-center w-full lg:items-start md:justify-start gap-y-8 lg:gap-y-2">
              <h1
                class="text-4xl  font-extrabold text-white font-montserrat lg:text-[30px] "
              >
                USE CODE
              </h1>
              <h2  class="text-6xl  text-[#01CCFF] font-extrabold font-montserrat lg:text-[80px] ">FUGROO</h2>
              <h3
                class="text-3xl  font-extrabold text-white font-montserrat lg:text-[26px] "
              >
                ON ROOBET.COM
              </h3>
            </div>
            </div>
              <div
                class="w-full lg:max-w-[400px] max-h-[100px] py-8 md:py-4 px-4 lg:py-0 lg:h-full lg:pb-32 lg:pt-2 lg:px-0"
              >
                <!-- Wrapper for Existing Button -->
                <div
                  class="flex items-center justify-center lg:justify-start h-[200px] lg:h-full rounded-full lg:py-4 mt-4"
                >
                <div class="relative shadow-[0_0_50px_#9A36F8] h-[72px] w-[300px] rounded-lg bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] 
    hover:shadow-[0_0_8px_4px_#01CCFF] transition-shadow duration-200">
              <div class="absolute inset-0 m-[2px] rounded-lg bg-gradient-to-r from-[#01CCFF] to-[#9A36F8] 
      overflow-hidden flex items-center justify-center">
                <a href="https://roobet.com/?ref=fugroo"
                  class="z-10 py-6 font-bold text-center text-white px-14 text-md whitespace-nowrap"
                  style="font-family: Lato, sans-serif;">
                  REGISTER ON ROOBET.COM NOW!
                </a>
                <img src="@/assets/hero-btn-style-5.png" alt="Decorative slant shadow"
                  class="absolute inset-y-0 left-0 h-full pointer-events-none" />
                <img src="@/assets/hero-btn-style-6.png" alt="Decorative slant shadow"
                  class="absolute inset-y-0 right-0 h-full pointer-events-none" />
              </div>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';

export default {
  data() {
    return {
      hoveredButton1: ref(false),
    };
  },
};
</script>
